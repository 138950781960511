import {
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

import React, { FC, useState } from 'react';
import { formatNumber } from '../../util';
import styles from './fuelCalc.module.css';

type CalcValues = {
  type: 'gas' | 'electric';
  consume: string;
  travel: string;
  price: string;
};

const calculateGas = (travel: number, consume: number, price: number) => ({
  price: (travel / 100) * consume * price,
  amount: (travel / 100) * consume,
  mpg: (100 * 0.621371) / (consume * 0.264172),
});

const calculateElectric = (travel: number, consume: number, price: number) => ({
  price: consume * travel * (price / 1000), // kulutus*matka*(hinta/1000)
  amount: (consume * travel) / 1000, // to kWh
});

const translations = {
  consumption: { gas: 'l / 100km', electric: 'Wh / km' },
  price: { gas: '€ / l', electric: 'c / kWh' },
};

const FuelCalc: FC = () => {
  const [values, setValues] = useState<CalcValues>({
    type: 'gas',
    consume: '0',
    travel: '100',
    price: '1,35',
  });

  const onChangeHandler = (name: keyof CalcValues) => (event) =>
    setValues({ ...values, [name]: event.target.value });

  const { type, consume, travel, price } = values as CalcValues;
  console.log(values);

  return (
    <Paper className={styles.paper}>
      <h1>Polttoainelaskuri</h1>
      <RadioGroup
        aria-label="polttoaine"
        name="gender1"
        value={type}
        onChange={onChangeHandler('type')}
      >
        <FormControlLabel value="gas" control={<Radio />} label="Polttoaine" />
        <FormControlLabel value="electric" control={<Radio />} label="Sähkö" />
      </RadioGroup>
      <form className={styles.form}>
        <FormControl className={styles.control}>
          <FormLabel className={styles.label}>
            Kulutus [{translations.consumption[type]}]
          </FormLabel>
          <TextField
            name="consume"
            onChange={onChangeHandler('consume')}
            value={consume}
          />
        </FormControl>

        <FormControl className={styles.control}>
          <FormLabel className={styles.label}>Matka [km]</FormLabel>
          <TextField
            name="travel"
            onChange={onChangeHandler('travel')}
            value={travel}
          />
        </FormControl>

        <FormControl className={styles.control}>
          <FormLabel className={styles.label}>
            Hinta [{translations.price[type]}]
          </FormLabel>
          <TextField
            name="price"
            onChange={onChangeHandler('price')}
            value={price}
          />
        </FormControl>
      </form>
      {type === 'gas' ? (
        <ResultsGas
          {...calculateGas(
            Number(travel.replace(',', '.')),
            Number(consume.replace(',', '.')),
            Number(price.replace(',', '.'))
          )}
        />
      ) : (
        <ResultsElectric
          {...calculateElectric(
            Number(travel.replace(',', '.')),
            Number(consume.replace(',', '.')),
            Number(price.replace(',', '.'))
          )}
        />
      )}
    </Paper>
  );
};

const ResultsGas: FC<{ amount: number; price: number; mpg: number }> = ({
  amount,
  price,
  mpg,
}) => {
  return (
    <div className={styles.result}>
      Hinta: {formatNumber(price)} €<br />
      Polttoainemäärä: {formatNumber(amount)} litraa
      <br />
      Mailia/Gallona: {formatNumber(mpg)} MPG
    </div>
  );
};

const ResultsElectric: FC<{ amount: number; price: number }> = ({
  amount,
  price,
}) => {
  return (
    <div className={styles.result}>
      Hinta: {formatNumber(price)} €<br />
      Sähkö: {formatNumber(amount)} kWh
    </div>
  );
};

export default FuelCalc;
